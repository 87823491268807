<template>
  <v-navigation-drawer app permanent expand-on-hover>
    <v-list-item class="pl-0">
      <v-img
        :src="require('@/assets/logo.png')"
        max-height="60"
        max-width="50"
      ></v-img>
      <v-list-item-content>
        <v-list-item-title class="title"> Gestor Academico </v-list-item-title>
        <v-list-item-subtitle> Estudiantil </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <template v-for="(menu, i) in menus">
        <v-list-group :key="i" no-action v-if="tieneAcceso(menu.roles)">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon >{{menu.icono}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.nombre }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="([title, icon, ruta, permisos], i) in menu.opciones">
            <v-list-item :key="i" link :to="ruta" v-if="tieneAcceso(permisos)">
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    rolesUsuario: [],
    menus: [
      {
        nombre: "Institución",
        roles: ["admin","coordinador","secretario"],
        icono: "mdi-office-building",
        opciones: [
          ["Calendario", "mdi-calendar-month-outline", "/periodos", ["admin"]],
          ["Grupos", "mdi-buffer", "/grupos", ["admin","coordinador","secretario"]],
          ["Usuarios", "mdi-account-group", "/registrarUsuarios", ["admin","secretario"]],
          ["Registro Estudiantes", "mdi-account-group", "/registroEstudiantes", ["admin","secretario","coordinador"]],
          
        ],
      },
      {
        nombre: "Academico",
        roles: ["admin", "docente","coordinador","secretario"],
        icono: "mdi-school",
        opciones: [
          ["Malla curricular", "mdi-cog-outline", "/malla", ["admin","coordinador"]],
          ["Logros", "mdi-format-list-text", "/logros", ["docente"]],
          ["Notas", "mdi-format-list-checks", "/notas", ["docente"]],
          ["Notas Nuevo", "mdi-format-list-checks", "/notasNuevo", ["admin","secretario"]],
          [
            "Generar boletines",
            "mdi-book-information-variant",
            "/generarBoletines",
            ["admin","coordinador","secretario"],
          ],
          [
            "Notas consolidadas",
            "mdi-book-information-variant",
            "/consolidadosNotas",
            ["admin","coordinador","secretario","docente"],
          ],
          [
            "Avance Notas",
            "mdi-book-information-variant",
            "/avanceNotas",
            ["admin","coordinador","secretario"],
          ],
          [
            "Items Calificación Notas",
            "mdi-book-information-variant",
            "/tiposItemRendimiento",
            ["admin","coordinador"],
          ]
        ],
      },
      {
        nombre: "Estudiantes",
        roles: [ "docente","estudiante","coordinador","admin","secretario"],
        icono:"mdi-account-group-outline",
        opciones: [
          ["Observador", "mdi-note-text", "/observador", [ "docente"]],
          ["Observador", "mdi-note-text", "/observadorEstudiante", [ "estudiante"]],
          ["Descargar Boletin",  "mdi-book-information-variant","/descargarBoletin",["estudiante"]],
          ["Editar",  "mdi-account-edit-outline","/editarEstudiante",["docente"]],
          ["Editar Estudiante",  "mdi-account-edit-outline","/editarEstudianteAdmin",["coordinador","admin","secretario"]],
          ["Traslado grupo",  "mdi-swap-horizontal","/traladoEstudiante",["coordinador","admin"]],
          ["Recuperaciones",  "mdi-account-edit-outline","/habilitarEstudiantes",["docente","admin"]],
          ["Agenda","mdi-book-information-variant","/agenda",["admin","coordinador","docente","estudiante"]]
        ],
      },
      {
        nombre: "Docentes",
        roles: ["admin"],
        icono:"mdi-teach",
        opciones: [
          ["Malla docente", "mdi-account-details", "/docentes", ["admin"]],
        ],
      },
    ],
  }),
  mounted(){
    this.GetUsuariosRoles().then((x)=> (this.rolesUsuario = x.data));
  },
  methods: {
    ...mapActions(["GetUsuariosRoles"]),
    tieneAcceso(permisos) {
      let roles = new Set(this.rolesUsuario);
      let intersection = new Set(permisos.filter((x) => roles.has(x)));
      return intersection.size > 0;
    },
  },
};
</script>