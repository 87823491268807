import axios from "axios";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    GetTiposItemRendimientoActivos({ commit }, datos){
        if(datos===null || datos===undefined){
            datos = {};
            datos.activo = true;
        }

        return axios.get(`api/TiposItemRendimiento/activo/${datos.activo}`);            
    },
    GetTiposItemRendimiento({ commit }, datos){
        return axios.get(`api/TiposItemRendimiento`);            
    },
    RegistarItemRendimiento({commit},datos){
        return axios.post('api/TiposItemRendimiento',datos);      
    },
    UpdateItemRendimiento({commit},datos){
        return axios.put(`api/TiposItemRendimiento/${datos.id}`,datos);
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};