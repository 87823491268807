import axios from "axios";

const state = {
    
};

const getters = {
    
};

const actions = {
    RegistrarBoletines({commit},datos){
        return axios.post('api/Boletines',datos);
    },
    DescargarTodoBoletines({commit},datos){
        return axios.get(`api/Boletines/periodo/${datos.periodo}/grupo/${datos.grupo}`);
    },
    ConsultarBoletinesEstudiante(){
        return axios.get('api/Boletines');
    },
    ListarBoletines({commit},datos){
        return axios.get(`api/Boletines/ListarBperiodo/${datos.periodo}/grupo/${datos.grupo}`);
    }

};

const mutations = {
   
};

export default {
    state,
    getters,
    actions,
    mutations,
};