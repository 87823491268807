
import Vuex from "vuex";
import Vue from "vue";
//import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import periodos from "./modules/periodos"
import malla from "./modules/malla"
import usuarios from "./modules/usuarios"
import grupos from "./modules/grupo"
import mallaPeriodo from "./modules/mallaPeriodo"
import rendimientoEscolar from "./modules/rendimientoEscolar"
import rendimientoEscolarAsignaturas from "./modules/rendimientoEscolarAsignaturas"
import asistencia from "./modules/asistencia"
import observador from "./modules/observador"
import boletines from "./modules/boletines"
import mensajes from "./modules/mensajes"
import estudiantes from "./modules/estudiantes"
import tiposItemRendimiento from "./modules/tiposItemRendimiento"
import agenda from "./modules/agenda"

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({

  state:{
    primerIngreso:false
  },
  getters: {
    ingreso: (state) => state.primerIngreso,
  },
  mutations: {
    SET_INGRESO(state, ingreso) {
      state.primerIngreso = ingreso;
    },
  },
  modules: {
    auth,
    periodos,
    malla,
    usuarios,
    grupos,
    mallaPeriodo,
    rendimientoEscolar,
    rendimientoEscolarAsignaturas,
    asistencia,
    observador,
    boletines,
    mensajes,
    estudiantes,
    tiposItemRendimiento,
    agenda
  },
 //plugins: [createPersistedState()],
});

/*import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    token: null,
    user: {},
  };
};

export default new Vuex.Store({
  state:getDefaultState,
  getters: {
    isAuthenticated: (state) => !!state.token,
    user: (state) => state.user,
    token: (state) => state.token,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    LOGIN_USER(state, user) {
      state.user = { ...user };
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async login({ commit }, authDetails) {
      const { data } = await apolloClient.mutate({
        mutation: LOGIN_USER,
        variables: { ...authDetails },
      });
      const token = data.login.uuid;
      commit("LOGIN_USER", data.login.user);
      commit("SET_TOKEN", token);
      localStorage.setItem("apollo-token", token);
      onLogin(apolloClient);
    },
    async logout({ commit }, token) {
      if (token) {
        const status = await apolloClient.mutate({
          mutation: LOGOUT_USER,
          variables: { sessionUuid: token },
        });
        if (!status.data.logout) console.log("Logout returned false");
      }
      localStorage.removeItem("apollo-token");
      commit("RESET_STATE");
      refreshApollo(apolloClient);
    },
  },
  modules: {
  }
})*/
//https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
//https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router
//https://github.com/gabbyprecious/vue-blog/tree/master/src/store
