<template>
  <v-app>
    <v-app-bar color="primary" dense dark app v-if="isLoggedIn">
      <v-toolbar-title>{{ nombre }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(opcion, index) in this.opciones"
            :key="index"
            @click="ejecutar(opcion.accion)"
          >
            <v-list-item-title> {{ opcion.nombre }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <Navegar v-if="isLoggedIn">
      <!-- -->
    </Navegar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <Mensajes></Mensajes>
      <!-- -->
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title> Cambio de constraseña </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="pswAnterior"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      label="Constraseña anterior"
                      :rules="[reglas.required, reglas.min]"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="pswNueva"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      label="Nueva constraseña"
                      :rules="[
                        reglas.required,
                        reglas.min,
                        reglas.lower,
                        reglas.upper,
                        reglas.num,
                        reglas.esp,
                      ]"
                      counter
                      @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="pswConfirmar"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      label="Confirmar constraseña"
                      :error-messages="msgError"
                      counter
                      @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!obligarCambioPassw"
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="savePassw">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-footer>
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Navegar from "@/components/Navegar.vue";
import Mensajes from "@/components/Mensajes.vue";
import { mapActions } from "vuex";
export default {
  components: {
    NavBar,
    Navegar,
    Mensajes,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  data: () => ({
    obligarCambioPassw: false,
    nombre: "Colegio Antonio Nariño",
    valid:false,
    msgError: "",
    dialog: false,
    show1: false,
    show2: false,
    pswAnterior: "",
    pswNueva: "",
    pswConfirmar: "",
    opciones: [
      {
        nombre: "Cerrar sesión",
        tipo: "url",
        accion: "cerrar",
      },
      { nombre: "Cambiar contraseña", tipo: "comp", accion: "cambio" },
    ],
    reglas: {
      required: (value) => !!value || "Requirido.",
      min: (v) => v.length >= 8 || "Min 8 caracteres.",
      lower: (v) => /[a-z]+/.test(v) || "Una minuscula.",
      upper: (v) => /[A-Z]+/.test(v) || "Una mayuscula.",
      num: (v) => /[0-9]+/.test(v) || "Un numero.",
      esp: (v) => /[!@#\$%\^\&*\)\(+=._-]/.test(v) || "Un caracter especial.",
    },
  }),
  methods: {
    ...mapActions(["UpdatePassw","ModificarMensaje"]),
    ejecutar(accion) {
      switch (accion) {
        case "cambio":
          this.CambiarPassw();
          break;
        case "cerrar":
          this.cerrarSesion();
          break;
      }
    },
    cerrarSesion() {
      this.$router.push("/authentication/logout");
    },
    CambiarPassw() {
      this.dialog = true;
    },
    savePassw() {
      if (this.pswNueva !== this.pswConfirmar ) {
        this.msgError = "Las contraseñas que escribo no coinciden";
      } else {
        if(this.valid)
        {
          let datos = {};
          datos.actual = this.pswAnterior;
          datos.nuevo = this.pswNueva;

          this.UpdatePassw(datos).then(x=>{
            this.dialog = false;
            this.ModificarMensaje({texto:'Cambio de constraseña Exitoso!'})
          });
          
        }
      }
    },
  },
  watch: {
    obligarCambioPassw(nuevoValor) {
      if (nuevoValor) {
        this.dialog = true;
      }
    },
  },
};
</script>

