import axios from "axios";

const state = {
    
};

const getters = {
    
};

const actions = {
    RegistrarObservador({commit},datos){
        return axios.post('api/Observador', datos);
    },
    GetObservador(){
        return axios.get('api/Observador');
    },
    GetEstudianteObservador({commit},estudiante){
        return axios.get(`api/Observador/Estudiante/${estudiante}`);
    },
    GetObservadorEstudianteSesion(){
        return axios.get(`api/Observador/Estudiante`);
    },
    GetEstudianteObservadorPeriodo({commit},datos){        
        return axios.get(`api/Observador/Estudiante/${datos.estudiante}/periodo/${datos.periodo}`);
    },
    UpdateObservador({commit},datos){        
        return axios.put(`api/Observador/${datos.id}`,datos);
    }
};

const mutations = {
   
};

export default {
    state,
    getters,
    actions,
    mutations,
};