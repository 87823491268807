import axios from "axios";

const state = {
    roles:['admin','docente','estudiante','acudiente','coordinador','secretario']
};

const getters = {
    getRoles:(state)=> state.roles,
};

const actions = {
  RegistrarUsuario({commit}, datos) {
    return axios.post('api/Usuarios', datos)
  },
  RegistrarUsuarioEstudiante({commit}, datos){
    return axios.post('api/Usuarios/Estudiantes', datos)
  },
  UpdateUsuarioEstudiante({commit}, datos){
    return axios.put(`api/Usuarios/Estudiantes`, datos)
  },
  UpdateUsuario({commit}, datos) {
    return axios.put(`api/Usuarios/${datos.id}`, datos)
  },
  UpdatePassw({commit}, datos){
    return axios.post('api/Usuarios/CambiarPass', datos)
  },
  GetUsuarios(){
    return axios.get('api/Usuarios');    
  },
  GetUsuariosEstudiante(){
    return axios.get('api/Usuarios/Estudiantes');    
  },
  GetDocentes(){
    return axios.get('api/Docentes');
  },
  GetAlumnos(){
    return axios.get('api/Estudiantes');
  },
  GetUsuariosRoles(){
    return axios.get('api/Usuarios/Roles');
  },
  GetUsuarioById({commit},datos){
    return axios.get(`api/Usuarios/${datos.id}`);
  }
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations,
};