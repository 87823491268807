import axios from "axios";

const state = {
    
};

const getters = {
    
};

const actions = {
    UpdateEstudiante({commit},datos){
        return axios.put(`api/Estudiantes/${datos.id}`,datos);
    },
    GetEstudiantesHabilitar({commit},datos){
        return axios.get(`api/Estudiantes/habilitar/periodo/${datos.periodo}/grupo/${datos.grupo}`)
    }

};

const mutations = {
   
};

export default {
    state,
    getters,
    actions,
    mutations,
};