import axios from "axios";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    GetRendimientoAsignaturasGrupo({ commit }, datos) {
        return axios.get(`api/RendimientoEscolarAsignaturas/periodo/${datos.periodo}/grupo/${datos.grupo}`);
    },
    UpdateRendimientoAsignaturaItems({commit}, datos){
        return axios.put(`api/RendimientoEscolarAsignaturas/${datos.id}`, datos);
    },
    UpdateHabilitarAsignatura({commit}, datos){
        return axios.put(`api/RendimientoEscolarAsignaturas/habilitar/${datos.estudianteId}/periodo/${datos.periodoId}/grupo/${datos.grupoId}/asignatura/${datos.asignaturaId}`);
    },
    GetRendimientoAsignaturasGrupoNuevos({ commit }, datos) {
        return axios.get(`api/RendimientoEscolarAsignaturas/nuevoEstudiante/${datos.periodo}/grupo/${datos.grupo}`);
    } 
};

export default {
    state,
    getters,
    actions,
    mutations,
};