import axios from "axios";

const state = {
  anuales:[],
 
};

const getters = {
  anual:(state)=> state.anuales,
  

};

const actions = {
  RegistrarPeriodo({dispatch}, datos) {
    datos.procentaje = parseFloat(datos.procentaje);
    datos.fechaInicio = new Date(datos.fechaInicio);
    datos.fechaFin = new Date(datos.fechaFin);
    return axios.post('api/Periodos', datos)
  },
  UpdatePeriodo({dispatch}, datos) {
    return axios.put(`api/Periodos/${datos.id}`, datos)
    
  },

  RegistrarAnual({commit},datos){
   
    return axios.post('api/Anual', datos)
  },
  RegistrarSedes({commit},datos){
   
    return axios.post('api/Sedes', datos)
  },
  GetAnuales({commit}){
    axios.get('api/Anual')
    .then(x=>{
      commit("SET_ANUALES", x.data);
    });
  },
  GetPeriodos(){
    return  axios.get('api/Periodos')
  }

  
};

const mutations = {
    SET_ANUALES(state, anuales) {
        state.anuales = anuales;
    },
   
    ADD_ANUALES(state,anual){
      state.anuales.push(anual);
    },
    ADD_SEDES(state,valor){
      state.sedes.push(valor);
    }
};

export default {
  state,
  getters,
  actions,
  mutations,
};