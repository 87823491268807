import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Register from "../views/Register";
import Login from "../plugins/api-authorization/Login.vue";
import Logout from "../plugins/api-authorization/Logout.vue";
import { ApplicationPaths, LoginActions, LogoutActions } from '../plugins/api-authorization/ApiAuthorizationConstants';


Vue.use(VueRouter);

const routes = [
  {
    path: ApplicationPaths.Login,
    name: "login",
    component: Login,
    props: { action: 'login' }
  },
  {
    path: ApplicationPaths.LoginFailed,
    name: "LoginFailed",
    component: Login,
    props: { action: LoginActions.LoginFailed }
  },
  {
    path: ApplicationPaths.LoginCallback,
    name: "LoginCallback",
    component: Login,
    props: { action: LoginActions.LoginCallback }
  },
  {
    path: ApplicationPaths.LogOut,
    name: "LogOut",
    component: Logout,
    props: { action: LogoutActions.Logout }
  },
  {
    path: ApplicationPaths.LogOutCallback,
    name: "LogOutCallback",
    component: Logout,
    props: { action: LogoutActions.LogoutCallback }
  },
  {
    path: ApplicationPaths.LoggedOut,
    name: "LoggedOut",
    component: Logout,
    props: { action: LogoutActions.LoggedOut }
  },
  {
    path:"/",
    name: "home",
    component: Home,
    meta: { requiresAuth:true }
  },
  {
    path:"/periodos",
    name: "periodos",
    component: () => import(/* webpackChunkName: "periodos" */ '../views/Adminstracion/AdminPeriodos'),
    meta: { requiresAuth:true }
  },
  {
    path:"/malla",
    name: "malla",
    component: () => import(/* webpackChunkName: "malla" */ '../views/Adminstracion/AdminMalla'),
    meta: { requiresAuth:true }
  },
  {
    path: "/registrarUsuarios",
    name: "registrarUsuarios",
    component: () => import(/* webpackChunkName: "registrarUsuarios" */ '../views/Adminstracion/AdminUsuarios'),
    meta: { requiresAuth:true },
  },
  {
    path: "/grupos",
    name: "grupos",
    component: () => import(/* webpackChunkName: "grupos" */ '../views/Adminstracion/AdminGrupos'),
    meta: { requiresAuth:true },
  },
  {
    path: "/docentes",
    name: "docentes",
    component: () => import(/* webpackChunkName: "docentes" */ '../views/Adminstracion/AdminMallaDocente'),
    meta: { requiresAuth:true },
  },
  {
    path: "/logros",
    name: "logros",
    component: () => import(/* webpackChunkName: "logros" */ '../views/Docentes/Logros'),
    meta: { requiresAuth:true },
  },
  {
    path: "/notas",
    name: "notas",
    component: () => import(/* webpackChunkName: "notas" */ '../views/Docentes/Notas'),
    meta: { requiresAuth:true },
  },
  {
    path: "/asistencia",
    name: "asistencia",
    component: () => import(/* webpackChunkName: "asistencia" */ '../views/Docentes/Asistencia'),
    meta: { requiresAuth:true },
  },
  {
    path: "/observador",
    name: "observador",
    component: () => import(/* webpackChunkName: "observador" */ '../views/Docentes/Observador'),
    meta: { requiresAuth:true },
  },
  {
    path: "/generarBoletines",
    name: "generarBoletines",
    component: () => import(/* webpackChunkName: "generarBoletines" */ '../views/Adminstracion/Boletines'),
    meta: { requiresAuth:true },
  },
  {
    path: "/descargarBoletin",
    name: "descargarBoletin",
    component: () => import(/* webpackChunkName: "descargarBoletin" */ '../views/Estudiantes/DescargarBoletines'),
    meta: { requiresAuth:true },
  },
  {
    path: "/editarEstudiante",
    name: "editarEstudiante",
    component: () => import(/* webpackChunkName: "editarEstudiante" */ '../views/Estudiantes/EditarInformacion'),
    meta: { requiresAuth:true },
  },
  {
    path: "/consolidadosNotas",
    name: "consolidadosNotas",
    component: () => import(/* webpackChunkName: "consolidadosNotas" */ '../views/Coordinador/ConsolidadosNotas'),
    meta: { requiresAuth:true },
  },
  {
    path: "/tiposItemRendimiento",
    name: "tiposItemRendimiento",
    component: () => import(/* webpackChunkName: "tiposItemRendimiento" */ '../views/Adminstracion/AdminTiposItemsRendimiento'),
    meta: { requiresAuth:true },
  },
  {
    path: "/agenda",
    name: "agenda",
    component: () => import(/* webpackChunkName: "agenda" */ '../views/Estudiantes/AdminAgenda'),
    meta: { requiresAuth:true },
  },
  {
    path: "/editarEstudianteAdmin",
    name: "editarEstudianteAdmin",
    component: () => import(/* webpackChunkName: "editarEstudianteAdmin" */ '../views/Estudiantes/EditarInformacionAdmin'),
    meta: { requiresAuth:true },
  },
  {
    path: "/traladoEstudiante",
    name: "traladoEstudiante",
    component: () => import(/* webpackChunkName: "traladoEstudiante" */ '../views/Coordinador/TrasladosEstudiantesGrupo'),
    meta: { requiresAuth:true },
  },
  {
    path: "/avanceNotas",
    name: "avanceNotas",
    component: () => import(/* webpackChunkName: "avanceNotas" */ '../views/Coordinador/AvanceCargarNotas'),
    meta: { requiresAuth:true },
  },
  {
    path: "/notasNuevo",
    name: "notasNuevo",
    component: () => import(/* webpackChunkName: "notasNuevo" */ '../views/Secretario/NotasNuevo'),
    meta: { requiresAuth:true },
  },
  {
    path: "/observadorEstudiante",
    name: "observadorEstudiante",
    component: () => import(/* webpackChunkName: "observadorEstudiante" */ '../views/Estudiantes/Observador'),
    meta: { requiresAuth:true },
  },
  {
    path: "/consolidadosNotasDirector",
    name: "consolidadosNotasDirector",
    component: () => import(/* webpackChunkName: "observadorEstudiante" */ '../views/Docentes/ConsolidadoDirector'),
    meta: { requiresAuth:true },
  },
  {
    path: "/registroEstudiantes",
    name: "registroEstudiantes",
    component: () => import(/* webpackChunkName: "observadorEstudiante" */ '../views/Estudiantes/Registro'),
    meta: { requiresAuth:true },
  },
  {
    path: "/habilitarEstudiantes",
    name: "habilitarEstudiantes",
    component: () => import(/* webpackChunkName: "observadorEstudiante" */ '../views/Docentes/Habilitar'),
    meta: { requiresAuth:true },
  }


  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next(ApplicationPaths.Login);
  } else {
    next();
  }
});



export default router;