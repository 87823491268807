import axios from "axios";

const state = {
 
};

const getters = {

};

const actions = {
  RegistrarMallaPeriodo({ commit }, datos) {    
    return axios.post('api/MallaPeriodos', datos);
  },
  ActualizarMallaPeriodoDocente({ commit }, datos) {
    return axios.put(`api/MallaPeriodos/docente/${datos.id}`,datos)
  },
  GetLogros({commit},periodo){
    return axios.get(`api/MallaPeriodos/logros/${periodo}`);
  },
  GetCursosDocente({commit},periodo){
    return axios.get(`api/MallaPeriodos/cursos/${periodo}`);
  },
  GetGruposDocente({commit},periodo){
    return axios.get(`api/MallaPeriodos/gruposDocente/${periodo}`);
  },
  GetGruposPeriodos({commit}, periodo){
    return axios.get(`api/MallaPeriodos/grupos/${periodo}`);
  },
  UpdateLogro({commit},mallaPeriodo){
    return axios.put(`api/MallaPeriodos/logro/${mallaPeriodo.id}`,mallaPeriodo)
  },

};

const mutations = {
 
};

export default {
  state,
  getters,
  actions,
  mutations,
};