<template>
  <v-snackbar v-model="estado" :color="color" :timeout="timeout" >
    {{ texto }}

    <template v-slot:action="{ attrs }">
      <v-btn dark  text v-bind="attrs" @click="estado = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script>
export default {
  data: () => ({
    timeout: 2000,
    estado:false,
    color:'',
    texto:''
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SET_MENSAJE") {
        this.texto = state.mensajes.texto;
        this.color = state.mensajes.color;
        this.timeout = state.mensajes.timeout;
        this.estado = true;
      }
    });
  },

};
</script>