import axios from "axios";

const state = {

};

const getters = {

};

const actions = {
    GetRendimientoGrupo({ commit }, datos) {
        return axios.get(`api/RendimientoEscolares/${datos.periodo}/grupo/${datos.grupo}`);
    },
    GetRendimientoGrupoNuevos({ commit }, datos) {
        return axios.get(`api/RendimientoEscolares/nuevoEstudiante/${datos.periodo}/grupo/${datos.grupo}`);
    },
    UpdateRendimiento({commit}, datos){
        return axios.put(`api/RendimientoEscolares/${datos.id}`, datos);
    },
    GetAvancesNotas({commit}, datos){
        return axios.get(`api/RendimientoEscolares/avances/${datos.periodo}/grupo/${datos.grupo}`);
    },
    UpdateHabilitar({commit},datos){
        return axios.put(`api/RendimientoEscolares/habilitar/${datos.estudianteId}/periodo/${datos.periodoId}/asignatura/${datos.asignaturaId}`);
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
};