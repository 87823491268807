var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.isLoggedIn)?_c('v-app-bar',{attrs:{"color":"primary","dense":"","dark":"","app":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.nombre))]),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',_vm._l((this.opciones),function(opcion,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.ejecutar(opcion.accion)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(opcion.nombre))])],1)}),1)],1)],1):_vm._e(),(_vm.isLoggedIn)?_c('Navegar'):_vm._e(),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{attrs:{"app":""}},[_c('Mensajes'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Cambio de constraseña ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"label":"Constraseña anterior","rules":[_vm.reglas.required, _vm.reglas.min],"counter":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.pswAnterior),callback:function ($$v) {_vm.pswAnterior=$$v},expression:"pswAnterior"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"label":"Nueva constraseña","rules":[
                      _vm.reglas.required,
                      _vm.reglas.min,
                      _vm.reglas.lower,
                      _vm.reglas.upper,
                      _vm.reglas.num,
                      _vm.reglas.esp ],"counter":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.pswNueva),callback:function ($$v) {_vm.pswNueva=$$v},expression:"pswNueva"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"label":"Confirmar constraseña","error-messages":_vm.msgError,"counter":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.pswConfirmar),callback:function ($$v) {_vm.pswConfirmar=$$v},expression:"pswConfirmar"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.obligarCambioPassw)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.savePassw}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }