const state = {
  texto: '',
  color:'success',
  timeout:2000,
};

const getters = {
  getMensaje: (state) => state.texto,
};

const actions = {
  ModificarMensaje({ commit }, mensaje) {
    commit('SET_MENSAJE', mensaje)
  },

};

const mutations = {
  SET_MENSAJE(state, mensaje) {
    state.texto = mensaje.texto;
    state.color = mensaje.color || state.color;
    state.timeout = mensaje.color || state.timeout;

  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};