import axios from "axios";

const state = {
    
};

const getters = {
    
};

const actions = {
    RegistrarAsistencia({commit},datos){
        return axios.post('api/Asistencias', datos);
    },

    RegistrarFallas({commit},datos){
        return axios.post('api/Fallas', datos);
    }
};

const mutations = {
   
};

export default {
    state,
    getters,
    actions,
    mutations,
};