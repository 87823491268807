import axios from "axios";

const state = {
  cusros: [],
  asignaturas: [],
  areas: []
};

const getters = {
  getCursos: (state) => state.cusros,
  getAsignaturas: (state) => state.asignaturas,
  getAreas: (state) => state.areas,

};

const actions = {
  RegistrarCursos({ commit }, datos) {
    return axios.post('api/Cursos', datos);
  },
  RegistrarAsignaturas({ commit }, datos) {
    return axios.post('api/Asignaturas', datos);
  },
  RegistrarAreas({ commit }, datos) {
    return axios.post('api/Areas', datos);
  },
  RegistrarMalla({ commit }, datos) {
    return axios.post('api/Mallas', datos)
  },
  UpdateMalla({commit},datos){
    return axios.put(`api/Malla/${datos.id}`, datos)
  },
  GetCursos({ commit }) {
    axios.get('api/Cursos')
      .then(x => {
        commit("SET_CURSOS", x.data);
      });
  },
  GetAsignaturas({ commit }) {
    axios.get('api/Asignaturas')
      .then(x => {
        commit("SET_ASIGNATURAS", x.data);
      });
  },
  GetMalla() {
    return axios.get('api/Mallas')
  },
  GetAreas({ commit }) {
    axios.get('api/Areas')
      .then(x => {
        commit("SET_AREAS", x.data);
      })
  },
  GetMallaGurso({ commit },datos){
    return axios.get(`api/Mallas/curso/${datos.cursoId}/periodo/${datos.periodoId}`);
  },
  GetMallaGrupo({ commit },datos){
    return axios.get(`api/Mallas/grupo/${datos.grupoId}/periodo/${datos.periodoId}`);
  }


};

const mutations = {
  SET_CURSOS(state, valor) {
    state.cusros = valor;
  },
  ADD_CURSOS(state, valor) {
    state.cusros.push(valor);
  },
  SET_ASIGNATURAS(state, valor) {
    state.asignaturas = valor;
  },
  ADD_ASIGNATURAS(state, valor) {
    state.asignaturas.push(valor);
  },
  SET_AREAS(state, valor) {
    state.areas = valor;
  },
  ADD_AREAS(state, valor) {
    state.areas.push(valor);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};