import axios from "axios";

const state = {
    
};

const getters = {
    
};

const actions = {
    GetTiposEvento(){
        return  axios.get('api/TipoEvento')
    },
    GetEventos({commit},datos){
        let filtros = `periodo/${datos.periodo}`;
        filtros+=`/grupo/${datos.grupo}`;
        filtros+=`/destinatario/${datos.destinatario}`;
        filtros+=`/fechaInicial/${datos.fechaInicial}`;
        filtros+=`/fechaFinal/${datos.fechaFinal}`;
        filtros+=`/tipoEvento/${datos.tipoEvento}`;
        filtros+=`/responsable/${datos.responsable}`;        
        filtros+=`/verInactivos/${datos.verInactivos}`;
        return  axios.get(`api/Evento/${filtros}`)
    },
    RegistrarEvento({commit},datos){
        return  axios.post('api/Evento',datos)
    },
    ActualizarEvento({commit},datos){
        return  axios.put('api/Evento',datos);
    },
    ActualizarRespuestaEvento({commit},datos){
        return  axios.put('api/Evento/respuesta',datos);
    },
    ObtenerHistorialEvento({commit},datos){
        let filtros =`evento/${datos.idEvento}/usuarioAsunto/${datos.usuarioAsunto}`;
        return  axios.get(`api/Evento/historial/${filtros}`,datos);
    },
};

const mutations = {
   
};

export default {
    state,
    getters,
    actions,
    mutations,
};