import axios from "axios";

const state = {
  user: null,
};

const getters = {
  isAuthenticated: (state) => state.user !== null,
  StateUser: (state) => state.user,
};

const actions = {
  async Register({dispatch}, form) {
    await axios.post('register', form)
    let UserForm = new FormData()
    UserForm.append('username', form.username)
    UserForm.append('password', form.password)
    await dispatch('LogIn', UserForm)
  },
   LogInStore({commit}, user) {
    const token =user && user.access_token;
    axios.defaults.headers.common['Authorization'] = `Bearer ${!token ? '' : token}`;
    commit("setUser", user);
  },
  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  },
};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },

  logout(state, user) {
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};