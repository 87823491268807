import axios from "axios";

const state = {
    sedes:[]
};

const getters = {
    sede:(state)=> state.sedes,
};

const actions = {
    GetGrupos({ commit }) {
        return axios.get('api/Grupos');
    },
    GetDirectorGrupo({commit},datos){
        return axios.get(`api/DirectorGrupos/${datos.grupo}/anual/${datos.anual}`);
        
    },
    GetAlumnosGrupo({commit},datos){
        return axios.get(`api/EstudianteGrupos/${datos.grupo}/anual/${datos.anual}`);
    },
    GetAlumnosGrupoPerido({commit},datos){
        return axios.get(`api/EstudianteGrupos/${datos.grupo}/periodo/${datos.periodo}`);
    },
    GetDocentesGrupo({commit},periodo){
        return axios.get(`api/Docentes/grupos/${periodo}`);
    },
    GetDocentesAsignaturas({commit},datos){
        return axios.get(`api/Docentes/asinagturas/${datos.periodo}/grupo/${datos.grupo}`)
    },
    GetGruposDirector({commit},periodo){
        return axios.get(`api/DirectorGrupos/grupos/periodo/${periodo}`);
    },
    GetGruposDirectorAnual({commit},anual){
        return axios.get(`api/DirectorGrupos/grupos/anual/${anual}`); 
    },
    GetSedes({commit}){
        axios.get('api/Sedes')
        .then(x=>{
          commit("SET_SEDES", x.data);
        });
      },
    RegistrarGrupos({commit},datos){
        return axios.post('api/Grupos', datos);
    },
    UpdateDirector({commit},datos){
        return axios.post('api/DirectorGrupos',datos);
    },
    UpdateGrupo({commit},datos){
        return axios.put(`api/Grupos/${datos.id}`, datos);
    },
    RegistarAlumnosGrupos({commit},datos){
        return axios.post('api/EstudianteGrupos',datos);
      
    },
    GetGruposEstudiante({commit},estudianteId){
        return axios.get(`api/EstudianteGrupos/Estudiante/${estudianteId}`);
    },
    UpdateGrupoPeriodoEstudiante({commit},datos){
        return axios.put(`api/EstudianteGrupos/${datos.grupo}/periodo/${datos.periodo}/estudiante/${datos.estudiante}`);
    },
    DeleteAlumnosGrupo({commit},id){
        return axios.delete(`api/EstudianteGrupos/${id}`);
    }
};

const mutations = {
    SET_SEDES(state, sedes) {
        state.sedes = sedes;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};