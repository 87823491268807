<template>
  <div>{{menssageShow}}</div>
</template>
<script>
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths,
} from "./ApiAuthorizationConstants";

export default {
  props: ["action"],
  data() {
    return {
      message: undefined,
      isReady: false,
      authenticated: false,
    };
  },
  mounted() {
    const action = this.action;
    switch (action) {
      case LogoutActions.Logout:
       
          this.logout(this.getReturnUrl());
        
        break;
      case LogoutActions.LogoutCallback:
        this.processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        this.isReady = true;
        this.message = "You successfully logged out!";
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }

    this.populateAuthenticationState();
  },
  computed:{
      menssageShow(){
          if (!this.isReady) {
            return "";
        }
        if (!!this.message) {
            return this.message;
           
        } else {
            const action = this.props.action;
            switch (action) {
                case LogoutActions.Logout:
                    return "Processing logout";
                case LogoutActions.LogoutCallback:
                    return "Processing logout callback";
                case LogoutActions.LoggedOut:
                    return this.message;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
      }
  },
  methods: {
    async logout(returnUrl) {
      const state = { returnUrl };
      const isauthenticated = await authService.isAuthenticated();
      if (isauthenticated) {
        const result = await authService.signOut(state);
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            break;
          case AuthenticationResultStatus.Success:
            await this.navigateToReturnUrl(returnUrl);
            break;
          case AuthenticationResultStatus.Fail:
            this.message = result.message;
            break;
          default:
            throw new Error("Invalid authentication result status.");
        }
      } else {
        this.message = "You successfully logged out!";
      }
    },

    async processLogoutCallback() {
      const url = window.location.href;
      const result = await authService.completeSignOut(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeAuthentication finishes
          // is when we are doing a redirect sign in flow.
          throw new Error("Should not redirect.");
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(this.getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          this.message = result.message;
          break;
        default:
          throw new Error("Invalid authentication result status.");
      }
    },

    async populateAuthenticationState() {
      const authenticated = await authService.isAuthenticated();
      this.isReady = true;
      this.authenticated = authenticated;
    },

    getReturnUrl(state) {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(
          "Invalid return url. The return url needs to have the same origin as the current page."
        );
      }
      return (
        (state && state.returnUrl) ||
        fromQuery ||
        `${window.location.origin}${ApplicationPaths.LoggedOut}`
      );
    },

    navigateToReturnUrl(returnUrl) {
      return window.location.replace(returnUrl);
    },
  },
};
</script>